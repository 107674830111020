<template>
  <div class="section-wrapper">
    <div class="sub-section-wrapper">
      <breadcrumbs :content="breadcrumbsContents"></breadcrumbs>
      <div class="radio-wrapper">
        <input
          type="text"
          placeholder="职位关键词"
          v-model="keyword"
          class="search-input"
          @keyup.enter="searchDo"
        />
        <span class="search-button" @click="searchDo"
          ><span class="icon-dl"><Icon type="ios-search" :size="20" /></span
          >查找</span
        >
      </div>
      <div class="wrapper-box">
        <div class="title-wrapper">
          <p>招募岗位</p>
        </div>
        <table class="main-table">
          <tr>
            <th>职位名称</th>
            <th>所属部门</th>
            <th>工作地点</th>
            <th>发布时间</th>
            <th>详情</th>
          </tr>
          <tr
            class="ele-item"
            v-for="(ele, index) in contentItems"
            :key="index"
          >
            <td>{{ ele.name }}</td>
            <td>{{ ele.department }}</td>
            <td>{{ ele.location }}</td>
            <td>{{ ele.pubdate }}</td>
            <td>
              <span
                @click="linkTo('/job_description/' + ele.id)"
                class="link-s"
              >
                查看详情<Icon type="ios-arrow-forward" />
              </span>
            </td>
          </tr>
        </table>
      </div>
      <div class="page-wrapper">
        <Page
          :total="totalItems"
          :page-size="Tpage.currentSize"
          @on-change="handlePage"
          class-name="page-d"
        />
      </div>
      <Spin v-if="spin" fix size="large"></Spin>
    </div>
  </div>
</template>
<script>
import breadcrumbs from "@/components/Libs/Breadcrumbs.vue";

export default {
  name: "recruitingList",
  components: {
    breadcrumbs,
  },
  props: {},
  mixins: [],
  data() {
    return {
      nameExtra: {
        url: "/recruiting_list",
        text: "菁英招募",
      },
      contentItems: [
        {
          id: 1,
          name: "检验员",
          department: "检测中心",
          location: "上海嘉定",
          pubdate: "2021-05-06",
          description:
            "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        },
        {
          id: 2,
          name: "认证工程师",
          department: "检测中心",
          location: "上海嘉定",
          pubdate: "2022-05-06",
          description:
            "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        },
        {
          id: 3,
          name: "客服",
          department: "检测中心",
          location: "上海嘉定",
          pubdate: "2021-02-06",
          description:
            "轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。轨道交通领域环境检测、振动噪声、电磁兼容、防火试验、电气安全检测；机车车辆型式试验、系统互操作性测试；材料及部件的力学性能、物理金相、化学分析、无损检测、失效分析。",
        },
      ],
      spin: false,
      Tpage: {
        currentPage: 1,
        currentSize: 12,
      },
      totalItems: 0,
      code: "jobs",
      lang: "中文",
      keyword: "",
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handlePage(value) {
      this.Tpage.currentPage = value;
      this.getData();
    },
    getData() {
      this.spin = true;
      this.$axios
        .post(this.$url + "/flist", {
          page: this.Tpage,
          askData: this.code,
          keyword: this.keyword,
          lang: this.lang,
        })
        .then((response) => {
          this.contentItems = response.data.res_record;
          this.totalItems = response.data.total_n;
          this.spin = false;
        });
    },
    downTo(url) {
      window.open(this.$fileUrlFacWithoutRight(url));
    },
    linkTo: function (value) {
      this.spin = true;
      location.href = value;
    },
    searchDo() {
      this.Tpage = {currentPage: 1,currentSize: 12,};
      this.totalItems = 0;
      this.getData();
    },
  },
  computed: {
    breadcrumbsContents: function () {
      return [
        { text: "首页", url: "/" },
        { text: this.nameExtra.text, url: this.nameExtra.url },
      ];
    },
  },
  mounted() {},
};
</script>
<style scoped>
.section-wrapper {
  width: 100%;
  margin-bottom: 100px;
}

.sub-section-wrapper {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}
.wrapper-box {
  padding: 30px 0 30px;
}

.main-table {
  width: 100%;
  border-collapse: collapse;
}

.main-table th {
  padding: 15px 5px;
  font-size: 16px;
  background: #f3f3f7;
}

.main-table td {
  padding: 15px 5px;
  font-size: 16px;
  text-align: center;
  color: #666;
}

.ele-item {
  width: 100%;
  padding: 0 15px;
  border-bottom: 1px solid #ddd;
}

.ele-item:hover {
  background: #fbfbfc;
}

.ele-item:hover td {
  color: #000;
}

.icon-dl {
  position: relative;
  top: 0px;
  right: 3px;
}

.page-wrapper {
  text-align: center;
  padding: 50px 0;
}

.link-s {
  cursor: pointer;
}

.link-s:hover {
  color: #00508e;
}

.radio-wrapper {
  position: absolute;
  right: 0;
  top: 50px;
}

.search-input {
  padding: 8px 15px;
  font-size: 16px;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}

.search-input:focus {
  border: 1px solid #00508e;
}

input::-webkit-input-placeholder {
  color: #bbb;
}
input::-moz-placeholder {
  color: #bbb;
}
input::-ms-input-placeholder {
  color: #bbb;
}

.search-button {
  padding: 12px 25px 13px;
  color: #ddd;
  font-weight: 400;
  font-size: 16px;
  background: #00508e;
  border: none;
  cursor: pointer;
}

.search-button:hover {
  color: #fff;
}

.title-wrapper {
  font-size: 38px;
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
  color: #00508e;
}
</style>